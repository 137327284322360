import ApiService from "@/core/services/api.service";
import Axios from "axios";

const ENDPOINT = "enrollment-process";

/**
 * Inicializando variable con la URL de la API de reportes según el ambiente de la aplicación
 */
let REPORT_API_URL = "";
const ENV = process.env.REPORTS_APP_ENV || "local";
if (ENV === "production") {
  REPORT_API_URL = process.env.REPORTS_API_URL;
} else if (ENV === "development") {
  REPORT_API_URL = process.env.REPORTS_DEV_API_URL;
} else {
  REPORT_API_URL = process.env.REPORTS_LOCAL_API_URL;
}

/**
 * Obteniendo el año de admisión
 */
const YEAR_OF_ADMISSION_ENROLLMENT_PROCESS =
  process.env.YEAR_OF_ADMISSION_ENROLLMENT_PROCESS;

export const getEnrollmentProcessOfStudent = (studentUUID) =>
  ApiService.get(`${ENDPOINT}/student/${studentUUID}`);

export const createEnrollmentProcess = (payload) =>
  ApiService.post(`${ENDPOINT}`, payload);

export const generateGroupOfDocuments = (studentCode, payload) =>
  Axios.post(
    `${REPORT_API_URL}/${ENDPOINT}/documents/${studentCode}`,
    payload,
    {
      responseType: "arraybuffer",
    }
  );

export const getFamilyMembers = (studentCode, studentUUID) => {
  if (
    studentCode.toString().substring(0, 4) !==
    YEAR_OF_ADMISSION_ENROLLMENT_PROCESS
  ) {
    return Axios.get(`https://apiform.ricaldone.edu.sv/api/student-managers`, {
      headers: {
        Authorization: `${studentUUID}`,
      },
    });
  }

  return Axios.get(
    "https://admision.ricaldone.edu.sv/app/controllers/php/solicitante_responsable.php?accion=buscar_por_codigo_estudiante_desde_ge&codigo=" +
      studentCode
  );
};

export const addStudentFamilyMember = (payload) =>
  Axios.post(
    "https://admision.ricaldone.edu.sv/app/controllers/php/solicitante_responsable.php?accion=crear_por_codigo",
    payload
  );

export const getKinships = () =>
  Axios.get(
    "https://admision.ricaldone.edu.sv/app/controllers/php/parentesco.php?accion=todo"
  );

export const getCivilStatuses = () =>
  Axios.get(
    "https://admision.ricaldone.edu.sv/app/controllers/php/estado_civil.php?accion=todo"
  );

export default {
  getEnrollmentProcessOfStudent,
  createEnrollmentProcess,
  generateGroupOfDocuments,
  getFamilyMembers,
  addStudentFamilyMember,
  getKinships,
  getCivilStatuses,
};
