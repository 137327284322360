<template>
    <!-- begin::Card -->
  <div class="card card-custom gutter-b">
    <div class="card-header flex-nowrap border-0 pt-6 pb-0">
      <div class="card-title">
        <h3 class="card-label">
          Administrar responsables de los estudiantes en proceso de matrícula
        </h3>
      </div>
      <div class="card-toolbar"></div>
    </div>
    <div class="card-body">
      <!-- begin::Filter -->
      <v-row class="mb-5">
        <v-col>
          <v-autocomplete
            label="Buscar estudiante por código ó nombre"
            :loading="areStudentsLoading"
            :disabled="areStudentsLoading || !students.length"
            filled
            rounded
            hide-details
            clearable
            :items="students"
            item-text="code"
            item-value="code"
            v-model="search"
            @change="getStudent()"
            :filter="customFilter"
          >
            <!-- begin::selected student item -->
            <template v-slot:selection="{ item, index }">
              <v-chip color="primary" small v-if="index === 0">
                <span>{{ item.full_name }}</span>
              </v-chip>
            </template>
            <!-- end::selected student item -->
            <template v-slot:item="{ item, index }">
              <v-list-item-content>
                <v-list-item-title>
                  <span class="font-weight-bold">
                    {{ item.code }}
                  </span>
                  -
                  <span class="text-uppercase font-weight-medium">
                    {{ item.full_name }}
                  </span>
                </v-list-item-title>
                <v-divider
                  class=""
                  v-if="index < filteredStudent.length - 1"
                ></v-divider>
              </v-list-item-content>
            </template>
          </v-autocomplete>
        </v-col>
      </v-row>
      <!-- end::Filter -->

      <v-scroll-y-reverse-transition
        :hide-on-leave="true"
        :leave-absolute="true"
      >
        <div v-if="showStepper">
          <v-container>
            <!-- begin::content card title -->
            <v-row>
              <v-col>
                <p class="text-h5 font-weight-medium">
                  <!-- Verificación de matrícula -->
                </p>
              </v-col>
            </v-row>
            <!-- end::content card title -->

            <!-- begin::Student Info card -->
            <v-card elevation="0" outlined class="mb-7 rounded-lg">
              <v-scroll-y-reverse-transition
                :hide-on-leave="true"
                :leave-absolute="true"
              >
                <div v-if="isLoadingStudentInfo" class="pa-5 my-4">
                  <v-row class="d-flex align-center flex-column">
                    <p class="text-h5 font-weight-bold mb-3">
                      Detalles de estudiante
                    </p>
                    <p class="text-subtitle font-weight-bold mb-3">
                      Cargando información...
                    </p>

                    <v-col cols="6">
                      <v-progress-linear
                        color="blue darken-1"
                        indeterminate
                        rounded
                        height="6"
                      ></v-progress-linear>
                    </v-col>
                  </v-row>
                </div>
              </v-scroll-y-reverse-transition>

              <v-scroll-y-reverse-transition
                :hide-on-leave="true"
                :leave-absolute="true"
              >
                <div v-if="!isLoadingStudentInfo">
                  <h2 class="ml-3 mt-4">Información del estudiante</h2>

                  <v-container>
                    <v-row>
                      <v-col>
                        <div class="black--text mb-1 flex-column">
                          <div class="text-body-2">Nombres</div>
                          <span
                            class="text-h6 font-weight-medium text-capitalize"
                            >{{ student.first_name }}</span
                          >
                        </div>
                      </v-col>
                      <v-col>
                        <div class="black--text mb-1 flex-column">
                          <div class="text-body-2">Apellidos</div>
                          <span
                            class="text-h6 font-weight-medium text-capitalize"
                            >{{ student.last_name }}</span
                          >
                        </div>
                      </v-col>
                      <v-col>
                        <div class="black--text mb-1 flex-column">
                          <div class="text-body-2">Grado que cursará</div>
                          <span class="text-h6 font-weight-medium">{{
                            student.grade
                          }}</span>
                        </div>
                      </v-col>
                      <v-col>
                        <div class="black--text mb-1 flex-column">
                          <div class="text-body-2">Especialidad a cursar</div>
                          <span class="text-h6 font-weight-medium">{{
                            student.speciality
                          }}</span>
                        </div>
                      </v-col>
                    </v-row>
                  </v-container>
                </div>
              </v-scroll-y-reverse-transition>
            </v-card>
            <!-- end::Student Info card -->

            <v-row>
                <v-col>
                    <v-card outlined class="pa-5 pb-3 rounded-lg elevation-0">
                        <h2>Listado de familiares registrados</h2>
                        <v-container>
                            <v-row>
                            <v-col v-for="(item, index) in familyInfo" :key="index" cols="6" md="4">
                                <div class="mb-2 light-blue lighten-5 px-4 py-3 rounded-lg">
                                    <h3 class="mb-0 grey--text text--darken-3 mt-1 font-weight-bold">{{ `${item.nombres} ${item.apellidos}` }}</h3>
                                    <p class="mb-0 grey--text text--darken-3 text-body-1 mt-1">
                                        Parentesco: 
                                        <span class="font-weight-bold"
                                        >{{ item.parentesco }}</span
                                        >
                                    </p>
                                    <p class="mb-0 grey--text text--darken-3 text-body-1 mt-1">
                                        DUI: 
                                        <span class="font-weight-bold"
                                        >{{ item.dui }}</span
                                        >
                                    </p>
                                    <p class="mb-0 grey--text text--darken-3 text-body-1 mt-1">
                                        Célular: 
                                        <span class="font-weight-bold"
                                        >{{ item.celular }}</span
                                        >
                                    </p>
                                    <p class="mb-0 grey--text text--darken-3 text-body-1 mt-1">
                                        Estado cívil: 
                                        <span class="font-weight-bold"
                                        >{{ item.estado_civil }}</span
                                        >
                                    </p>
                                    <p class="mb-0 grey--text text--darken-3 text-body-1 mt-1">
                                        Fecha de nacimiento: 
                                        <span class="font-weight-bold"
                                        >{{ item.fecha_nacimiento }}</span
                                        >
                                    </p>
                                    <p class="mb-0 grey--text text--darken-3 text-body-1 mt-1">
                                        E-mail: 
                                        <span class="font-weight-bold"
                                        >{{ item.correo }}</span
                                        >
                                    </p>
                                </div>
                            </v-col>
                            </v-row>
                        </v-container>
                    </v-card>
                </v-col>
            </v-row>

            <v-row>
                <v-col>
                    <v-card outlined class="pa-5 pb-3 rounded-lg elevation-0">
                        <h2>Agregar familiar</h2>
                        <v-form ref="addFamilyMember">
                            <v-container>
                                <v-row>
                                    <v-col cols="12" md="3" lg="4">
                                        <form-group
                                            name="nombres"
                                            :validator="$v.newFamilyMember.nombres"
                                        >
                                            <v-text-field
                                                slot-scope="{ attrs }"
                                                v-bind="attrs"
                                                v-model="newFamilyMember.nombres"
                                                label="Nombres"
                                                placeholder="Nombres"
                                                required
                                                filled
                                                rounded
                                                dense
                                            ></v-text-field>
                                        </form-group>
                                    </v-col>
                                    <v-col cols="12" md="3" lg="4">
                                        <form-group
                                            name="apellidos"
                                            :validator="$v.newFamilyMember.apellidos"
                                        >
                                            <v-text-field
                                                slot-scope="{ attrs }"
                                                v-bind="attrs"
                                                v-model="newFamilyMember.apellidos"
                                                label="Apellidos"
                                                placeholder="Apellidos"
                                                required
                                                filled
                                                rounded
                                                dense
                                            ></v-text-field>
                                        </form-group>
                                    </v-col>
                                    <v-col cols="12" md="3" lg="4">
                                        <form-group
                                            name="DUI"
                                            :validator="$v.newFamilyMember.dui"
                                        >
                                            <v-text-field
                                                slot-scope="{ attrs }"
                                                v-bind="attrs"
                                                v-model="newFamilyMember.dui"
                                                label="DUI"
                                                placeholder="DUI"
                                                required
                                                filled
                                                rounded
                                                dense
                                                v-mask="'########-#'"
                                            ></v-text-field>
                                        </form-group>
                                    </v-col>
                                    <v-col cols="12" md="3" lg="4">
                                        <form-group
                                            name="fecha de nacimiento"
                                            :validator="$v.newFamilyMember.fecha_nacimiento"
                                        >
                                            <v-text-field
                                                slot-scope="{ attrs }"
                                                v-bind="attrs"
                                                v-model="newFamilyMember.fecha_nacimiento"
                                                label="Fecha de nacimiento"
                                                placeholder="(yyyy-mm-dd)"
                                                v-mask="'####-##-##'"
                                                required
                                                filled
                                                rounded
                                                dense
                                            ></v-text-field>
                                        </form-group>
                                    </v-col>
                                    <v-col cols="12" md="3" lg="4">
                                        <form-group
                                            name="municipio"
                                            :validator="$v.newFamilyMember.id_municipio"
                                        >
                                            <v-autocomplete
                                                slot-scope="{ attrs }"
                                                v-bind="attrs"
                                                v-model="newFamilyMember.id_municipio"
                                                label="Municipio"
                                                placeholder="Municipio"
                                                required
                                                filled
                                                rounded
                                                dense
                                                :items="municipalities"
                                                item-text="municipio"
                                                item-value="id_municipio"
                                            ></v-autocomplete>
                                        </form-group>
                                    </v-col>
                                    <v-col cols="12" md="3" lg="4">
                                        <form-group
                                            name="parentesco"
                                            :validator="$v.newFamilyMember.id_parentesco"
                                        >
                                            <v-select
                                                slot-scope="{ attrs }"
                                                v-bind="attrs"
                                                v-model="newFamilyMember.id_parentesco"
                                                label="Parentesco"
                                                placeholder="Parentesco"
                                                required
                                                filled
                                                rounded
                                                dense
                                                :items="kinships"
                                                item-text="parentesco"
                                                item-value="id_parentesco"
                                            ></v-select>
                                        </form-group>
                                    </v-col>
                                    <v-col cols="12" md="3" lg="4">
                                        <form-group
                                            name="estado cívil"
                                            :validator="$v.newFamilyMember.id_estado_civil"
                                        >
                                            <v-select
                                                slot-scope="{ attrs }"
                                                v-bind="attrs"
                                                v-model="newFamilyMember.id_estado_civil"
                                                label="Estado cívil"
                                                placeholder="Estado cívil"
                                                required
                                                filled
                                                rounded
                                                dense
                                                :items="civilStatuses"
                                                item-text="estado_civil"
                                                item-value="id_estado_civil"
                                            ></v-select>
                                        </form-group>
                                    </v-col>
                                    <v-col cols="12" md="3" lg="4">
                                        <form-group
                                            name="teléfono"
                                            :validator="$v.newFamilyMember.telefono"
                                        >
                                            <v-text-field
                                                slot-scope="{ attrs }"
                                                v-bind="attrs"
                                                v-model="newFamilyMember.telefono"
                                                label="Teléfono"
                                                placeholder="Teléfono"
                                                required
                                                filled
                                                rounded
                                                dense
                                                v-mask="'########'"
                                            ></v-text-field>
                                        </form-group>
                                    </v-col>
                                    <v-col cols="12" md="3" lg="4">
                                        <form-group
                                            name="celular"
                                            :validator="$v.newFamilyMember.celular"
                                        >
                                            <v-text-field
                                                slot-scope="{ attrs }"
                                                v-bind="attrs"
                                                v-model="newFamilyMember.celular"
                                                label="Celular"
                                                placeholder="Celular"
                                                required
                                                filled
                                                rounded
                                                dense
                                                v-mask="'########'"
                                            ></v-text-field>
                                        </form-group>
                                    </v-col>
                                    <v-col cols="12" md="3" lg="4">
                                        <form-group
                                            name="profesión"
                                            :validator="$v.newFamilyMember.profesion"
                                        >
                                            <v-text-field
                                                slot-scope="{ attrs }"
                                                v-bind="attrs"
                                                v-model="newFamilyMember.profesion"
                                                label="Profesión"
                                                placeholder="Profesión"
                                                required
                                                filled
                                                rounded
                                                dense
                                            ></v-text-field>
                                        </form-group>
                                    </v-col>
                                    <v-col cols="12" md="3" lg="4">
                                        <form-group
                                            name="correo"
                                            :validator="$v.newFamilyMember.correo"
                                        >
                                            <v-text-field
                                                slot-scope="{ attrs }"
                                                v-bind="attrs"
                                                v-model="newFamilyMember.correo"
                                                label="Correo"
                                                placeholder="Correo"
                                                required
                                                filled
                                                rounded
                                                dense
                                                type="email"
                                            ></v-text-field>
                                        </form-group>
                                    </v-col>
                                    <v-col cols="12" md="3" lg="4">
                                        <form-group
                                            name="lugar de trabajo"
                                            :validator="$v.newFamilyMember.lugar_trabajo"
                                        >
                                            <v-text-field
                                                slot-scope="{ attrs }"
                                                v-bind="attrs"
                                                v-model="newFamilyMember.lugar_trabajo"
                                                label="Lugar de trabajo"
                                                placeholder="Lugar de trabajo"
                                                required
                                                filled
                                                rounded
                                                dense
                                            ></v-text-field>
                                        </form-group>
                                    </v-col>
                                    <v-col cols="12" md="3" lg="4">
                                        <form-group
                                            name="teléfono del lugar de trabajo"
                                            :validator="$v.newFamilyMember.telefono_trabajo"
                                        >
                                            <v-text-field
                                                slot-scope="{ attrs }"
                                                v-bind="attrs"
                                                v-model="newFamilyMember.telefono_trabajo"
                                                label="Teléfono del lugar de trabajo"
                                                placeholder="Teléfono del lugar de trabajo"
                                                required
                                                filled
                                                rounded
                                                dense
                                                v-mask="'########'"
                                            ></v-text-field>
                                        </form-group>
                                    </v-col>
                                    <v-col cols="12" md="3" lg="4">
                                        <form-group
                                            name="extensión"
                                            :validator="$v.newFamilyMember.extension"
                                        >
                                            <v-text-field
                                                slot-scope="{ attrs }"
                                                v-bind="attrs"
                                                v-model="newFamilyMember.extension"
                                                label="Extensión"
                                                placeholder="Extensión"
                                                required
                                                filled
                                                rounded
                                                dense
                                            ></v-text-field>
                                        </form-group>
                                    </v-col>
                                    <v-col cols="12" md="3" lg="4">
                                        <form-group
                                            name="dirección de trabajo"
                                            :validator="$v.newFamilyMember.direccion_trabajo"
                                        >
                                            <v-textarea
                                                slot-scope="{ attrs }"
                                                v-bind="attrs"
                                                v-model="newFamilyMember.direccion_trabajo"
                                                label="Dirección de trabajo"
                                                placeholder="Dirección de trabajo"
                                                required
                                                filled
                                                rounded
                                                dense
                                                auto-grow
                                                rows="2"
                                            ></v-textarea>
                                        </form-group>
                                    </v-col>
                                    <v-col>
                                        <v-row>
                                            <v-switch
                                                v-model="newFamilyMember.responsable"
                                                :label="`Responsable ante la institución: ${newFamilyMember.responsable ? 'Sí' : 'No'}`"
                                            ></v-switch>
                                        </v-row>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col>
                                        <v-btn
                                            :disabled="isFamilyMemberBeingSaved"
                                            color="primary"
                                            block
                                            :loading="isFamilyMemberBeingSaved"
                                            @click="submit"
                                        >
                                        <v-icon class="mr-1"
                                            >mdi-account-plus</v-icon
                                        >
                                        Agregar responsable
                                        </v-btn>
                                    </v-col>
                                </v-row>
                            </v-container>
                        </v-form>
                    </v-card>
                </v-col>
            </v-row>
          </v-container>
        </div>
      </v-scroll-y-reverse-transition>

      <v-scroll-y-reverse-transition
        :hide-on-leave="true"
        :leave-absolute="true"
      >
        <div v-if="!showStepper">
          <v-card class="pa-10 elevation-0">
            <v-row class="d-flex align-center flex-column">
              <v-col cols="6" class="d-flex align-center flex-column">
                <v-avatar color="red lighten-4" size="100">
                  <v-icon color="red">mdi-account-group-outline</v-icon>
                </v-avatar>
                <p class="mt-4 text-h6 font-weight-medium text-center">
                  Para agregar información de responsables, por favor
                  <span class="font-weight-bold"
                    >digite un código de estudiante</span
                  >, busque por <span class="font-weight-bold">nombre</span> ó
                  <span class="font-weight-bold">apellido</span>.
                </p>
              </v-col>
            </v-row>
          </v-card>
        </div>
      </v-scroll-y-reverse-transition>
    </div>
  </div>
  <!-- end::Card -->
</template>

<script>import Swal from "sweetalert2";
import { mapGetters } from "vuex";
import { required, email } from "vuelidate/lib/validators";

import studentRepository from "@/repositories/studentRepository";
import enrollmentProcessRepository from "@/repositories/enrollmentProcessRepository";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import {
  GET_CURRENT_PAGE_ACTIONS,
  PURGE_CURRENT_PAGE_ACTIONS,
} from "@/core/services/store/role.module";


export default {
    name: "ManageStudentsFamilies",
    data() {
        return {
            areStudentsLoading: false,
            isLoadingStudentInfo: false,
            students: [],
            search: "",
            showStepper: false,
            familyInfo: [],
            filteredStudent: [],
            newFamilyMember: {
                nombres: "",
                apellidos: "",
                dui: "",
                fecha_nacimiento: "",
                id_municipio: null,
                id_parentesco: null,
                id_estado_civil: null,
                telefono: "",
                celular: "",
                profesion: "",
                correo: "",
                lugar_trabajo: "",
                telefono_trabajo: "",
                extension: "",
                direccion_trabajo: "",
                responsable: false,
            },
            isFamilyMemberBeingSaved: false,
            municipalities: [
                { id_municipio: 1, municipio:	"Ahuachapán" },
                { id_municipio: 2, municipio:	"Jujutla" },
                { id_municipio: 3, municipio:	"Atiquizaya" },
                { id_municipio: 4, municipio:	"Concepción de Ataco" },
                { id_municipio: 5, municipio:	"El Refugio" },
                { id_municipio: 6, municipio:	"Guaymango" },
                { id_municipio: 7, municipio:	"Apaneca" },
                { id_municipio: 8, municipio:	"San Francisco Menéndez" },
                { id_municipio: 9, municipio:	"San Lorenzo" },
                { id_municipio: 10, municipio:	"San Pedro Puxtla" },
                { id_municipio: 11, municipio:	"Tacuba" },
                { id_municipio: 12, municipio:	"Turín" },
                { id_municipio: 13, municipio:	"Candelaria de la Frontera" },
                { id_municipio: 14, municipio:	"Chalchuapa" },
                { id_municipio: 15, municipio:	"Coatepeque" },
                { id_municipio: 16, municipio:	"El Congo" },
                { id_municipio: 17, municipio:	"El Porvenir" },
                { id_municipio: 18, municipio:	"Masahuat" },
                { id_municipio: 19, municipio:	"Metapán" },
                { id_municipio: 20, municipio:	"San Antonio Pajonal" },
                { id_municipio: 21, municipio:	"San Sebastián Salitrillo" },
                { id_municipio: 22, municipio:	"Santa Ana" },
                { id_municipio: 23, municipio:	"Santa Rosa Guachipilín" },
                { id_municipio: 24, municipio:	"Santiago de la Frontera" },
                { id_municipio: 25, municipio:	"Texistepeque" },
                { id_municipio: 26, municipio:	"Acajutla" },
                { id_municipio: 27, municipio:	"Armenia" },
                { id_municipio: 28, municipio:	"Caluco" },
                { id_municipio: 29, municipio:	"Cuisnahuat" },
                { id_municipio: 30, municipio:	"Izalco" },
                { id_municipio: 31, municipio:	"Juayúa" },
                { id_municipio: 32, municipio:	"Nahuizalco" },
                { id_municipio: 33, municipio:	"Nahulingo" },
                { id_municipio: 34, municipio:	"Salcoatitán" },
                { id_municipio: 35, municipio:	"San Antonio del Monte" },
                { id_municipio: 36, municipio:	"San Julián" },
                { id_municipio: 37, municipio:	"Santa Catarina Masahuat" },
                { id_municipio: 38, municipio:	"Santa Isabel Ishuatán" },
                { id_municipio: 39, municipio:	"Santo Domingo de Guzmán" },
                { id_municipio: 40, municipio:	"Sonsonate" },
                { id_municipio: 41, municipio:	"Sonzacate" },
                { id_municipio: 42, municipio:	"Alegría" },
                { id_municipio: 43, municipio:	"Berlín" },
                { id_municipio: 44, municipio:	"California" },
                { id_municipio: 45, municipio:	"Concepción Batres" },
                { id_municipio: 46, municipio:	"El Triunfo" },
                { id_municipio: 47, municipio:	"Ereguayquín" },
                { id_municipio: 48, municipio:	"Estanzuelas" },
                { id_municipio: 49, municipio:	"Jiquilisco" },
                { id_municipio: 50, municipio:	"Jucuapa" },
                { id_municipio: 51, municipio:	"Jucuarán" },
                { id_municipio: 52, municipio:	"Mercedes Umaña" },
                { id_municipio: 53, municipio:	"Nueva Granada" },
                { id_municipio: 54, municipio:	"Ozatlán" },
                { id_municipio: 55, municipio:	"Puerto El Triunfo" },
                { id_municipio: 56, municipio:	"San Agustín" },
                { id_municipio: 57, municipio:	"San Buenaventura" },
                { id_municipio: 58, municipio:	"San Dionisio" },
                { id_municipio: 59, municipio:	"San Francisco Javier" },
                { id_municipio: 60, municipio:	"Santa Elena" },
                { id_municipio: 61, municipio:	"Santa María" },
                { id_municipio: 62, municipio:	"Santiago de María" },
                { id_municipio: 63, municipio:	"Tecapán" },
                { id_municipio: 64, municipio:	"Usulután" },
                { id_municipio: 65, municipio:	"Carolina" },
                { id_municipio: 66, municipio:	"Chapeltique" },
                { id_municipio: 67, municipio:	"Chinameca" },
                { id_municipio: 68, municipio:	"Chirilagua" },
                { id_municipio: 69, municipio:	"Ciudad Barrios" },
                { id_municipio: 70, municipio:	"Comacarán" },
                { id_municipio: 71, municipio:	"El Tránsito" },
                { id_municipio: 72, municipio:	"Lolotique" },
                { id_municipio: 73, municipio:	"Moncagua" },
                { id_municipio: 74, municipio:	"Nueva Guadalupe" },
                { id_municipio: 75, municipio:	"Nuevo Edén de San Juan" },
                { id_municipio: 76, municipio:	"Quelepa" },
                { id_municipio: 77, municipio:	"San Antonio del Mosco" },
                { id_municipio: 78, municipio:	"San Gerardo" },
                { id_municipio: 79, municipio:	"San Jorge" },
                { id_municipio: 80, municipio:	"San Luis de la Reina" },
                { id_municipio: 81, municipio:	"San Miguel" },
                { id_municipio: 82, municipio:	"San Rafael Oriente" },
                { id_municipio: 83, municipio:	"Sesori" },
                { id_municipio: 84, municipio:	"Uluazapa" },
                { id_municipio: 85, municipio:	"Arambala" },
                { id_municipio: 86, municipio:	"Cacaopera" },
                { id_municipio: 87, municipio:	"Chilanga" },
                { id_municipio: 88, municipio:	"Corinto" },
                { id_municipio: 89, municipio:	"Delicias de Concepción" },
                { id_municipio: 90, municipio:	"El Divisadero" },
                { id_municipio: 91, municipio:	"El Rosario" },
                { id_municipio: 92, municipio:	"Gualococti" },
                { id_municipio: 93, municipio:	"Guatajiagua" },
                { id_municipio: 94, municipio:	"Joateca" },
                { id_municipio: 95, municipio:	"Jocoaitique" },
                { id_municipio: 96, municipio:	"Jocoro" },
                { id_municipio: 97, municipio:	"Lolotiquillo" },
                { id_municipio: 98, municipio:	"Meanguera" },
                { id_municipio: 99, municipio:	"Osicala" },
                { id_municipio: 100, municipio:	"Perquín" },
                { id_municipio: 101, municipio:	"San Carlos" },
                { id_municipio: 102, municipio:	"San Fernando" },
                { id_municipio: 103, municipio:	"San Francisco Gotera" },
                { id_municipio: 104, municipio:	"San Isidro" },
                { id_municipio: 105, municipio:	"San Simón" },
                { id_municipio: 106, municipio:	"Sensembra" },
                { id_municipio: 107, municipio:	"Sociedad" },
                { id_municipio: 108, municipio:	"Torola" },
                { id_municipio: 109, municipio:	"Yamabal" },
                { id_municipio: 110, municipio:	"Yoloaiquín" },
                { id_municipio: 111, municipio:	"La Unión" },
                { id_municipio: 112, municipio:	"San Alejo" },
                { id_municipio: 113, municipio:	"Yucuaiquín" },
                { id_municipio: 114, municipio:	"Conchagua" },
                { id_municipio: 115, municipio:	"Intipucá" },
                { id_municipio: 116, municipio:	"San José" },
                { id_municipio: 117, municipio:	"El Carmen" },
                { id_municipio: 118, municipio:	"Yayantique" },
                { id_municipio: 119, municipio:	"Bolívar" },
                { id_municipio: 120, municipio:	"Meanguera del Golfo" },
                { id_municipio: 121, municipio:	"Santa Rosa de Lima" },
                { id_municipio: 122, municipio:	"Pasaquina" },
                { id_municipio: 123, municipio:	"Anamoros" },
                { id_municipio: 124, municipio:	"Nueva Esparta" },
                { id_municipio: 125, municipio:	"El Sauce" },
                { id_municipio: 126, municipio:	"Concepción de Oriente" },
                { id_municipio: 127, municipio:	"Polorós" },
                { id_municipio: 128, municipio:	"Lislique" },
                { id_municipio: 129, municipio:	"Antiguo Cuscatlán" },
                { id_municipio: 130, municipio:	"Chiltiupán" },
                { id_municipio: 131, municipio:	"Ciudad Arce" },
                { id_municipio: 132, municipio:	"Colón" },
                { id_municipio: 133, municipio:	"Comasagua" },
                { id_municipio: 134, municipio:	"Huizúcar" },
                { id_municipio: 135, municipio:	"Jayaque" },
                { id_municipio: 136, municipio:	"Jicalapa" },
                { id_municipio: 137, municipio:	"La Libertad" },
                { id_municipio: 138, municipio:	"Santa Tecla" },
                { id_municipio: 139, municipio:	"Nuevo Cuscatlán" },
                { id_municipio: 140, municipio:	"San Juan Opico" },
                { id_municipio: 141, municipio:	"Quezaltepeque" },
                { id_municipio: 142, municipio:	"Sacacoyo" },
                { id_municipio: 143, municipio:	"San José Villanueva" },
                { id_municipio: 144, municipio:	"San Matías" },
                { id_municipio: 145, municipio:	"San Pablo Tacachico" },
                { id_municipio: 146, municipio:	"Talnique" },
                { id_municipio: 147, municipio:	"Tamanique" },
                { id_municipio: 148, municipio:	"Teotepeque" },
                { id_municipio: 149, municipio:	"Tepecoyo" },
                { id_municipio: 150, municipio:	"Zaragoza" },
                { id_municipio: 151, municipio:	"Agua Caliente" },
                { id_municipio: 152, municipio:	"Arcatao" },
                { id_municipio: 153, municipio:	"Azacualpa" },
                { id_municipio: 154, municipio:	"Cancasque" },
                { id_municipio: 155, municipio:	"Chalatenango" },
                { id_municipio: 156, municipio:	"Citalá" },
                { id_municipio: 157, municipio:	"Comapala" },
                { id_municipio: 158, municipio:	"Concepción Quezaltepeque" },
                { id_municipio: 159, municipio:	"Dulce Nombre de María" },
                { id_municipio: 160, municipio:	"El Carrizal" },
                { id_municipio: 161, municipio:	"El Paraíso" },
                { id_municipio: 162, municipio:	"La Laguna" },
                { id_municipio: 163, municipio:	"La Palma" },
                { id_municipio: 164, municipio:	"La Reina" },
                { id_municipio: 165, municipio:	"Las Vueltas" },
                { id_municipio: 166, municipio:	"Nueva Concepción" },
                { id_municipio: 167, municipio:	"Nueva Trinidad" },
                { id_municipio: 168, municipio:	"Nombre de Jesús" },
                { id_municipio: 169, municipio:	"Ojos de Agua" },
                { id_municipio: 170, municipio:	"Potonico" },
                { id_municipio: 171, municipio:	"San Antonio de la Cruz" },
                { id_municipio: 172, municipio:	"San Antonio Los Ranchos" },
                { id_municipio: 173, municipio:	"San Fernando" },
                { id_municipio: 174, municipio:	"San Francisco Lempa" },
                { id_municipio: 175, municipio:	"San Francisco Morazán" },
                { id_municipio: 176, municipio:	"San Ignacio" },
                { id_municipio: 177, municipio:	"San Isidro Labrador" },
                { id_municipio: 178, municipio:	"Las Flores" },
                { id_municipio: 179, municipio:	"San Luis del Carmen" },
                { id_municipio: 180, municipio:	"San Miguel de Mercedes" },
                { id_municipio: 181, municipio:	"San Rafael" },
                { id_municipio: 182, municipio:	"Santa Rita" },
                { id_municipio: 183, municipio:	"Tejutla" },
                { id_municipio: 184, municipio:	"Cojutepeque" },
                { id_municipio: 185, municipio:	"Candelaria" },
                { id_municipio: 186, municipio:	"El Carmen" },
                { id_municipio: 187, municipio:	"El Rosario" },
                { id_municipio: 188, municipio:	"Monte San Juan" },
                { id_municipio: 189, municipio:	"Oratorio de Concepción" },
                { id_municipio: 190, municipio:	"San Bartolomé Perulapía" },
                { id_municipio: 191, municipio:	"San Cristóbal" },
                { id_municipio: 192, municipio:	"San José Guayabal" },
                { id_municipio: 193, municipio:	"San Pedro Perulapán" },
                { id_municipio: 194, municipio:	"San Rafael Cedros" },
                { id_municipio: 195, municipio:	"San Ramón" },
                { id_municipio: 196, municipio:	"Santa Cruz Analquito" },
                { id_municipio: 197, municipio:	"Santa Cruz Michapa" },
                { id_municipio: 198, municipio:	"Suchitoto" },
                { id_municipio: 199, municipio:	"Tenancingo" },
                { id_municipio: 200, municipio:	"Aguilares" },
                { id_municipio: 201, municipio:	"Apopa" },
                { id_municipio: 202, municipio:	"Ayutuxtepeque" },
                { id_municipio: 203, municipio:	"Cuscatancingo" },
                { id_municipio: 204, municipio:	"Ciudad Delgado" },
                { id_municipio: 205, municipio:	"El Paisnal" },
                { id_municipio: 206, municipio:	"Guazapa" },
                { id_municipio: 207, municipio:	"Ilopango" },
                { id_municipio: 208, municipio:	"Mejicanos" },
                { id_municipio: 209, municipio:	"Nejapa" },
                { id_municipio: 210, municipio:	"Panchimalco" },
                { id_municipio: 211, municipio:	"Rosario de Mora" },
                { id_municipio: 212, municipio:	"San Marcos" },
                { id_municipio: 213, municipio:	"San Martín" },
                { id_municipio: 214, municipio:	"San Salvador" },
                { id_municipio: 215, municipio:	"Santiago Texacuangos" },
                { id_municipio: 216, municipio:	"Santo Tomás" },
                { id_municipio: 217, municipio:	"Soyapango" },
                { id_municipio: 218, municipio:	"Tonacatepeque" },
                { id_municipio: 219, municipio:	"Zacatecoluca" },
                { id_municipio: 220, municipio:	"Cuyultitán" },
                { id_municipio: 221, municipio:	"El Rosario" },
                { id_municipio: 222, municipio:	"Jerusalén" },
                { id_municipio: 223, municipio:	"Mercedes La Ceiba" },
                { id_municipio: 224, municipio:	"Olocuilta" },
                { id_municipio: 225, municipio:	"Paraíso de Osorio" },
                { id_municipio: 226, municipio:	"San Antonio Masahuat" },
                { id_municipio: 227, municipio:	"San Emigdio" },
                { id_municipio: 228, municipio:	"San Francisco Chinameca" },
                { id_municipio: 229, municipio:	"San Pedro Masahuat" },
                { id_municipio: 230, municipio:	"San Juan Nonualco" },
                { id_municipio: 231, municipio:	"San Juan Talpa" },
                { id_municipio: 232, municipio:	"San Juan Tepezontes" },
                { id_municipio: 233, municipio:	"San Luis La Herradura" },
                { id_municipio: 234, municipio:	"San Luis Talpa" },
                { id_municipio: 235, municipio:	"San Miguel Tepezontes" },
                { id_municipio: 236, municipio:	"San Pedro Nonualco" },
                { id_municipio: 237, municipio:	"San Rafael Obrajuelo" },
                { id_municipio: 238, municipio:	"Santa María Ostuma" },
                { id_municipio: 239, municipio:	"Santiago Nonualco" },
                { id_municipio: 240, municipio:	"Tapalhuaca" },
                { id_municipio: 241, municipio:	"Cinquera" },
                { id_municipio: 242, municipio:	"Dolores" },
                { id_municipio: 243, municipio:	"Guacotecti" },
                { id_municipio: 244, municipio:	"Ilobasco" },
                { id_municipio: 245, municipio:	"Jutiapa" },
                { id_municipio: 246, municipio:	"San Isidro" },
                { id_municipio: 247, municipio:	"Sensuntepeque" },
                { id_municipio: 248, municipio:	"Tejutepeque" },
                { id_municipio: 249, municipio:	"Victoria" },
                { id_municipio: 250, municipio:	"Apastepeque" },
                { id_municipio: 251, municipio:	"Guadalupe" },
                { id_municipio: 252, municipio:	"San Cayetano Istepeque" },
                { id_municipio: 253, municipio:	"San Esteban Catarina" },
                { id_municipio: 254, municipio:	"San Ildefonso" },
                { id_municipio: 255, municipio:	"San Lorenzo" },
                { id_municipio: 256, municipio:	"San Sebastián" },
                { id_municipio: 257, municipio:	"San Vicente" },
                { id_municipio: 258, municipio:	"Santa Clara" },
                { id_municipio: 259, municipio:	"Santo Domingo" },
                { id_municipio: 260, municipio:	"Tecoluca" },
                { id_municipio: 261, municipio:	"Tepetitán" },
                { id_municipio: 262, municipio:	"Verapaz" },
                { id_municipio: 263, municipio:	"Ishuatán" },
                { id_municipio: 264, municipio:	"San José Las Flores" },
                { id_municipio: 265, municipio:	"Villa el Triunfo" },
                { id_municipio: 266, municipio:	"San José Cancasque" },
            ],
            civilStatuses: [],
            kinships: [],
        }
    },
    validations: {
        newFamilyMember: {
            nombres: {
                required,
            },
            apellidos: {
                required,
            },
            dui: {
                required,
            },
            fecha_nacimiento: {
                required,
            },
            id_municipio: {
                required,
            },
            id_parentesco: {
                required,
            },
            id_estado_civil: {
                required,
            },
            telefono: { },
            celular: {
                required,
            },
            profesion: {
                required,
            },
            correo: {
                required,
                email,
            },
            lugar_trabajo: { },
            telefono_trabajo: { },
            extension: { },
            direccion_trabajo: { },
        }
    },
    methods: {
        customFilter(item, queryText, itemText) {
            const searchText = queryText.toLowerCase();
            const codeMatch = item.code.toString().includes(searchText);
            const fullNameMatch = item.full_name.toLowerCase().includes(searchText);
            return codeMatch || fullNameMatch;
        },
        getStudents() {
            this.areStudentsLoading = true;
            studentRepository
                .getEnrollableStudents()
                    .then(({ data }) => {
                    this.students = data;
                    })
                    .catch(() => {
                    this.fireToast({
                        icon: "error",
                        title: "No ha sido posible cargar los datos desde el servidor",
                    });
                    })
                    .finally(() => {
                        this.areStudentsLoading = false;
                    });
        },

        getStudent() {
            const code = this.search;

            //Check if there's a code
            if (!code) {
                this.showStepper = false;
                return;
            }

            this.showStepper = true;

            // if there's a code, reset al variables and make request
            this.isLoadingStudentInfo = true;
            this.student = {};

            studentRepository
                .getStudentEnrollmentByCode(code)
                .then(({ data }) => {
                if (!data.status) {
                    this.sweetAlertResponse({
                    status: false,
                    message: data.message,
                    });
                } else {
                    this.student = data.data;
                    this.getFamilyInfo(this.student.code);
                }
                })
                .catch((error) => {
                console.log(error);
                
                // Sweetalert that indicates the operation cannot be completed
                    this.fireToast({
                        icon: "error",
                        title:
                        "No ha sido posible cargar los datos del estudiante desde el servidor",
                    });
                })
                .finally(() => {
                    this.isLoadingStudentInfo = false;
                });
        },

        getFamilyInfo(studentCode) {
            enrollmentProcessRepository
                .getFamilyMembers(studentCode, null).then(({data}) => {
                    this.familyInfo = data;
                }).catch((err) => {
                    console.error(err.message);
                });
        },

        addStudentFamilyMember() {
            this.isFamilyMemberBeingSaved = true;
            const payload = {
                ...this.newFamilyMember,
                codigo: this.student.code,
                id_nacionalidad: 1,
                nit: "0000-000000-000-0"
            }
            console.log(payload);

            enrollmentProcessRepository
                .addStudentFamilyMember(this.objectToFormData(payload))
                    .then(({ data }) => {
                        if (data == true || data == "true") {
                            this.getFamilyInfo(this.student.code);
                            this.$refs.addFamilyMember.reset();
                            this.$v.$reset();
                            this.newFamilyMember = {
                                nombres: "",
                                apellidos: "",
                                dui: "",
                                fecha_nacimiento: "",
                                id_municipio: null,
                                id_parentesco: null,
                                id_estado_civil: null,
                                telefono: "",
                                celular: "",
                                profesion: "",
                                correo: "",
                                lugar_trabajo: "",
                                telefono_trabajo: "",
                                extension: "",
                                direccion_trabajo: "",
                                responsable: false,
                            }
                        } else {
                            this.fireToast({
                                icon: "error",
                                title: data,
                            });
                        }
                    }).catch((err) => {
                        console.error("Error on family member creation: " + err.message)
                    }).finally(() => {
                        this.isFamilyMemberBeingSaved = false;
                    });
        },
        submit() {
            this.$v.$touch();
            if (this.$v.$invalid) {
                this.fireToast({
                    icon: "error",
                    title: "Asegurate de llenar el formulario correctamente",
                });
                return;
            }
            this.addStudentFamilyMember();
        },

        getCivilStatuses() {
            enrollmentProcessRepository
                .getCivilStatuses().then(({data}) => {
                    this.civilStatuses = data;
                }).catch((err) => {
                    console.error(err.message);
                });
        },

        getKinships() {
            enrollmentProcessRepository
                .getKinships().then(({data}) => {
                    this.kinships = data;
                }).catch((err) => {
                    console.error(err.message);
                });
        },
    },
    watch: {
        isLoadingStudentInfo(newV) {
            if (newV) {
                if (this.$refs.addFamilyMember) {
                    this.$refs.addFamilyMember.reset();
                }
                this.$v.$reset();
                this.familyInfo = [];
                this.newFamilyMember = {
                    nombres: "",
                    apellidos: "",
                    dui: "",
                    fecha_nacimiento: "",
                    id_municipio: null,
                    id_parentesco: null,
                    id_estado_civil: null,
                    telefono: "",
                    celular: "",
                    profesion: "",
                    correo: "",
                    lugar_trabajo: "",
                    telefono_trabajo: "",
                    extension: "",
                    direccion_trabajo: "",
                    responsable: false,
                }
            }
        }
    },
    mounted () {
        this.$store.dispatch(SET_BREADCRUMB, [
            { title: "Gestionar responsables", route: "manage_students_families" },
        ]);
        this.$store.dispatch(GET_CURRENT_PAGE_ACTIONS, "Gestionar responsables");
        this.getStudents();
        this.getCivilStatuses();
        this.getKinships();
    },
    beforeDestroy() {
        this.$store.commit(PURGE_CURRENT_PAGE_ACTIONS);
    },
}
</script>